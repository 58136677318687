<template>
  <div class="Detail" style="bottom:0;">
      <vueTemplate viewType="seas" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" ref="vueTemplate"></vueTemplate>
  </div>
</template>
<script>
import vueTemplate from '../Detail/Vue/index.vue'
import titleMixin from '@/mixin/title'
export default {
  name: 'Detail_seas',
  title: '客户信息',
  mixins: [titleMixin],
  props: {
    sysBoxValue: {
      type: Array,
      default: function () {
        return []
      }
    },
    cusBoxValue: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.vueTemplate.beforeLeave(next)
  },
  methods: {
  },
  watch: {
    '$route': function (val, old) {
      if (val.path == this.pageUrl) {
        this.$refs.vueTemplate.updateData(val.params.id, true)
      } else if (val.path.indexOf('/client/seas_detail') != -1) {
        this.pageUrl = val.path
        this.$refs.vueTemplate.updateData(val.params.id)
      }
    }
  },
  components: {
    'vueTemplate': vueTemplate
  }
}
</script>

<style lang="less" scoped>
@import './zh-cn.less';
@import './en.less';
</style>
